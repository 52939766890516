@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #1f1717;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

#ba-map,
#ba-map-ext {
	display: inline-block;
	vertical-align: top;
}
#col2 .infoline,
#directions {
	margin: 10px auto;
}
#ba-map-ext,
#col2 .infoline,
#directions > .row,
#directions > .row > div,
#mapcontext,
#showpoke {
	text-align: center;
}
#showpoke,
.button input {
	color: #000;
}
.def-text {
	font-size: 11px;
	line-height: 16px;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}
.def-text2 {
	font-size: 14px;
	line-height: 20px;
}
#sasprites > .spritecontainer > .mpus {
	cursor: pointer;
}
#col2 .infoline {
	font-weight: 700;
	font-size: 12px;
}
#loadmap {
	margin: 10px;
}
#ba-map {
	height: 496px;
	width: 496px;
	margin: 2px;
	box-sizing: border-box;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}
#ba-map-ext {
	width: 220px;
	border-radius: 15px;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
	min-height: 496px;
	background-color: #fff;
}
#pokemonimg {
	width: 96px;
	height: 96px;
}
#pokemonimgwrap {
	height: 110px;
	display: block;
}
#dirparent {
	background-color: transparent;
	padding: 15px 0;
}
#directions img {
	border: 0;
}
#directions > .row {
	min-width: 110px;
}
#directions > .row > div {
	display: inline-block;
	width: 32px;
	height: 32px;
	border: 0;
	margin: 0;
	padding: 0;
}
#directions > .row > div > div {
	background-image: url(../public/mparw.png);
	width: 32px;
	height: 32px;
}
#directions > .row > div > #dr-nw.m-move {
	background-position: -96px 64px;
}
#directions > .row > div > #dr-nw.m-disable {
	background-position: -96px 0;
}
#directions > .row > div > #dr-n.m-move {
	background-position: -64px 64px;
}
#directions > .row > div > #dr-n.m-disable {
	background-position: -64px 0;
}
#directions > .row > div > #dr-ne.m-move {
	background-position: -32px 64px;
}
#directions > .row > div > #dr-ne.m-disable {
	background-position: -32px 0;
}
#directions > .row > div > #dr-w.m-move {
	background-position: -96px 32px;
}
#directions > .row > div > #dr-w.m-disable {
	background-position: -96px 96px;
}
#directions > .row > div > #dr-e.m-move {
	background-position: 0 64px;
}
#directions > .row > div > #dr-e.m-disable {
	background-position: 0 0;
}
#directions > .row > div > #dr-sw.m-move {
	background-position: -64px 32px;
}
#directions > .row > div > #dr-sw.m-disable {
	background-position: -64px 96px;
}
#directions > .row > div > #dr-s.m-move {
	background-position: -32px 32px;
}
#directions > .row > div > #dr-s.m-disable {
	background-position: -32px 96px;
}
#directions > .row > div > #dr-se.m-move {
	background-position: 0 32px;
}
#directions > .row > div > #dr-se.m-disable {
	background-position: 0 96px;
}
#showpoke {
	background-color: #b7ffc7;
	padding: 0 5px;
	font-size: 110%;
	font-weight: bolder;
	height: 260px;
}
#showpoke > a {
	font-weight: 700;
}
#showpoke .dupmarksp {
	font-family: Ubuntu, sans-serif;
	border-radius: 7px;
	background-color: #000;
	color: #fff;
	padding: 3px;
	position: relative;
	top: -6px;
	left: 6px;
}
#mapcontext,
#showpoke.backwhite {
	background-color: #fff;
}
#mapcontext {
	padding: 5px;
	margin: 0;
	color: #000;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
	border-bottom: 1px #e6e6e6 solid;
}
#mapcontext.basic {
	height: 20px;
	line-height: 20px;
	font-variant: small-caps;
}
#spicons {
	position: relative;
}
#spicons > .spicon {
	max-width: 20px;
	max-height: 20px;
	position: absolute;
	top: 5px;
	font-size: 15px;
}
#spicons > .spia {
	right: 5px;
}
#spicons > .spib {
	left: 0;
}
#hp {
	color: #000;
	font-weight: lighter;
	font-size: 110%;
}
#d-keys {
	display: block;
	text-align: center;
	clear: right;
}
#dirsprite,
.btn-catch-action {
	display: inline-block;
	text-align: center;
}
#searching {
	text-align: center;
	padding-top: 70px;
	font-size: 150%;
	font-variant: small-caps;
}
#usersonpage {
	position: static;
	margin-left: 200px;
}
#dexy {
	color: #1a1a1a;
}
#spritedirections {
	position: relative;
	top: 20px;
}
#odiwrap > .light105 {
	font-weight: lighter;
	font-size: 105%;
}
#havenotpkbl {
	float: none;
	margin: 0 10px;
	width: 600px;
}
#ldsprites,
#sasprites {
	height: 0;
}
#sasprites {
	position: relative;
	top: 0;
	left: 0;
}
.btn-catch-action {
	color: #fff;
	background-color: #115dcb;
	font-weight: bolder;
	font-variant: small-caps;
	letter-spacing: 1px;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: 1px solid transparent;
	white-space: nowrap;
	padding: 6px 12px;
	font-size: 16px;
	line-height: 18px;
	border-radius: 5px;
}
.btn-catch-action:focus {
	background-color: #0d479c;
	border-color: #072755;
}
.btn-catch-action:active,
.btn-catch-action:hover {
	background-color: #0d479c;
	border-color: #0b3d84;
}
.btn-catch-action.disabled.focus,
.btn-catch-action.disabled:focus,
.btn-catch-action.disabled:hover,
.btn-catch-action[disabled].focus,
.btn-catch-action[disabled]:focus,
.btn-catch-action[disabled]:hover,
fieldset[disabled] .btn-catch-action.focus,
fieldset[disabled] .btn-catch-action:focus,
fieldset[disabled] .btn-catch-action:hover {
	background-color: #2275ed;
	border-color: #115dcb;
}
